import { render, staticRenderFns } from "./blue-money-icon.vue?vue&type=template&id=7c0657a1&scoped=true&"
import script from "./blue-money-icon.ts?vue&type=script&lang=ts&"
export * from "./blue-money-icon.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c0657a1",
  null
  
)

export default component.exports